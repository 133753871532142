import {
    EventCategoryType,
    GenderEnum,
    PersonalInfoEnum,
    ParticipateType,
    AttachmentType,
    BrandType,
    CacheBackTypeEnum,
    CategoryType,
    ProfileType,
    ReviewType,
    ServiceType,
    AttachmentUsageEnum,
    AttachmentTypeEnum,
    OrderType,
    OrderStatusEnum,
    EventType,
    DatingProfileType,
    LocationEnum,
    HeightEnum,
    SleepEnum,
    BestsBrandsType,
} from 'types';
import { ParticipateStatusEnum, RateCommentEnum } from '@/types/index';

const imageData: AttachmentType[] = [
    {
        id: 1,
        file: 'https://static.vecteezy.com/system/resources/previews/009/098/314/non_2x/people-icon-boy-free-vector.jpg',
        alt: 'profile',
        usage: AttachmentUsageEnum.profile,
        type: AttachmentTypeEnum.image,
    },
    {
        id: 2,
        file: 'https://static.vecteezy.com/system/resources/previews/001/926/758/non_2x/road-in-park-free-photo.JPG',
        alt: 'brand 1',
        usage: AttachmentUsageEnum.content,
        type: AttachmentTypeEnum.image,
    },
    {
        id: 3,
        file: 'https://static.vecteezy.com/system/resources/previews/002/286/681/non_2x/view-of-modern-business-skyscraper-glass-and-sky-view-landscape-of-commercial-building-in-a-central-city-free-photo.jpg',
        alt: 'brand 2',
        usage: AttachmentUsageEnum.content,
        type: AttachmentTypeEnum.image,
    },
    {
        id: 4,
        file: 'https://static.vecteezy.com/system/resources/previews/029/180/704/non_2x/professional-logo-design-for-your-brand-free-vector.jpg',
        alt: 'brand 3',
        usage: AttachmentUsageEnum.content,
        type: AttachmentTypeEnum.image,
    },
    {
        id: 5,
        file: 'https://static.vecteezy.com/system/resources/previews/010/055/451/non_2x/fire-and-ice-concept-design-free-photo.jpg',
        alt: 'brand 4',
        usage: AttachmentUsageEnum.content,
        type: AttachmentTypeEnum.image,
    },
    {
        id: 6,
        file: 'https://static.vecteezy.com/system/resources/previews/001/308/505/non_2x/professional-programmer-working-at-company-office-free-photo.jpg',
        alt: 'service 1',
        usage: AttachmentUsageEnum.content,
        type: AttachmentTypeEnum.image,
    },
    {
        id: 7,
        file: 'https://static.vecteezy.com/system/resources/previews/001/373/054/non_2x/business-man-checking-graphs-and-charts-free-photo.jpg',
        alt: 'service 2',
        usage: AttachmentUsageEnum.content,
        type: AttachmentTypeEnum.image,
    },
    {
        id: 8,
        file: 'https://static.vecteezy.com/system/resources/previews/001/225/069/non_2x/close-up-of-a-handshake-between-two-professionals-free-photo.jpg',
        alt: 'service 3',
        usage: AttachmentUsageEnum.content,
        type: AttachmentTypeEnum.image,
    },
    {
        id: 1,
        file: 'https://static.vecteezy.com/system/resources/previews/007/359/326/non_2x/financial-businessmen-analyze-the-graph-of-the-company-s-performance-to-create-profits-and-growth-market-research-reports-and-income-statistics-financial-and-accounting-concept-free-photo.JPG',
        alt: 'service 4',
        usage: AttachmentUsageEnum.content,
        type: AttachmentTypeEnum.image,
    },
    {
        id: 9,
        file: 'https://static.vecteezy.com/system/resources/previews/005/522/083/non_2x/3d-render-modern-office-design-manager-room-interior-wall-mockup-free-photo.jpg',
        alt: 'service 5',
        usage: AttachmentUsageEnum.content,
        type: AttachmentTypeEnum.image,
    },
    {
        id: 10,
        file: 'https://static.vecteezy.com/system/resources/previews/001/901/314/non_2x/green-mountains-under-white-clouds-during-daytime-free-photo.jpeg',
        alt: 'service 6',
        usage: AttachmentUsageEnum.content,
        type: AttachmentTypeEnum.image,
    },
    {
        id: 11,
        file: 'https://static.vecteezy.com/system/resources/previews/025/044/832/non_2x/natures-close-up-beauty-vibrant-flowers-defocused-background-generative-ai-free-photo.jpg',
        alt: 'service 7',
        usage: AttachmentUsageEnum.content,
        type: AttachmentTypeEnum.image,
    },
];

const categoryDataEN: CategoryType[] = [
    {
        title: 'Newcomers',
        slug: 'newcomers',
        id: 1,
    },
    {
        title: 'Travel and Accommodation',
        slug: 'travel_and_accommodation',
        id: 2,
    },
    {
        title: 'Immigration Services',
        slug: 'immigration_services',
        id: 3,
    },
    { title: 'Financial Services', slug: 'financial_services', id: 4 },
    {
        id: 5,
        title: 'Legal Services',
        slug: 'legal_services',
    },
    {
        id: 6,
        title: 'Education',
        slug: 'educations',
    },
    {
        id: 7,
        title: 'Goods',
        slug: 'goods',
    },
    {
        id: 8,
        title: 'Health and Beauty',
        slug: 'healthy_and_beauty',
    },
    {
        id: 9,
        title: 'Family Services',
        slug: 'family_services',
    },
    {
        id: 10,
        title: 'Property',
        slug: 'property',
    },
    {
        id: 11,
        title: 'Cars',
        slug: 'cars',
    },
    {
        id: 12,
        title: 'Maintenance',
        slug: 'maintenance',
    },
    {
        id: 13,
        title: 'Entertainment',
        slug: 'entertainment',
    },
];
const categoryDataFA: CategoryType[] = [
    {
        id: 13,
        title: 'تازه واردین',
        slug: 'newcomers',
    },
    {
        id: 14,
        title: 'سفر و اقامت',
        slug: 'travel_and_accommodation',
    },
    {
        id: 15,
        title: 'مهاجرت',
        slug: 'immigration_services',
    },
    {
        id: 16,
        title: 'مالی',
        slug: 'financial_services',
    },
    {
        id: 17,
        title: 'حقوقی',
        slug: 'legal_services',
    },
    {
        id: 18,
        title: 'تحصیلی',
        slug: 'educations',
    },
    {
        id: 19,
        title: 'کالا',
        slug: 'goods',
    },
    {
        id: 20,
        title: 'سلامتی و زیبایی',
        slug: 'healthy_and_beauty',
    },
    {
        id: 21,
        title: 'خانواده',
        slug: 'family_services',
    },
    {
        id: 22,
        title: 'املاک',
        slug: 'property',
    },
    {
        id: 23,
        title: 'خودرو',
        slug: 'cars',
    },
    {
        id: 24,
        title: 'تعمیرات',
        slug: 'maintenance',
    },
    {
        id: 25,
        title: 'تفریحات',
        slug: 'entertainment',
    },
];
const categoryData = {
    fa: categoryDataFA,
    en: categoryDataEN,
};
// const categoryData: CategoryType[] = [
//     {
//         title: 'خدمات مالی',
//         slug: 'خدمات_مالی',
//     },
//     {
//         title: 'امور مهاجرتی',
//         slug: 'امور_مهاجرتی',
//     },
//     {
//         title: 'خدمات حقوقی',
//         slug: 'خدمات_حقوقی',
//     },
//     {
//         title: 'سلامت و زیبایی',
//         slug: 'سلامت_و_زیبایی',
//     },
//     {
//         title: 'سفر و اقامت',
//         slug: 'سفر_و_اقامت',
//     },
//     {
//         title: 'اجاره و خرید خانه',
//         slug: 'اجاره_و_خرید_خانه',
//     },
//     {
//         title: 'تازه واردین',
//         slug: 'تازه_واردین',
//     },
//     {
//         title: 'پزشک عمومی',
//         slug: 'پزشک_عمومی',
//         parent: 'سلامت_و_زیبایی',
//     },
//     {
//         title: 'دندان پزشکی',
//         slug: 'دندان_پزشکی',
//         parent: 'سلامت_و_زیبایی',
//     },
//     {
//         title: 'پزشک متخصص',
//         slug: 'پزشک_متخصص',
//         parent: 'سلامت_و_زیبایی',
//     },
//     {
//         title: 'بیمارستان',
//         slug: 'بیمارستان',
//         parent: 'سلامت_و_زیبایی',
//     },
//     {
//         title: 'پوست و مو',
//         slug: 'پوست_و_مو',
//         parent: 'سلامت_و_زیبایی',
//     },
//     {
//         title: 'کلینیک ها',
//         slug: 'کلینیک_ها',
//         parent: 'سلامت_و_زیبایی',
//     },
//     {
//         title: 'بیمه درمانی',
//         slug: 'بیمه_درمانی',
//         parent: 'سلامت_و_زیبایی',
//     },
//     {
//         title: 'خدمات غیرحضوری',
//         slug: 'خدمات_غیرحضوری',
//         parent: 'سلامت_و_زیبایی',
//     },
//     {
//         title: 'بیمه خانواده',
//         slug: 'بیمه_خانواده',
//         parent: 'سلامت_و_زیبایی',
//     },
// ];

const BestBrands: BestsBrandsType[] = [
    { id: 1, slug: '', title: 'علاالدین مهاجر', image: imageData[0], down_vote: 175, up_vote: 45 },
    { id: 2, slug: '', title: 'آژانس رها و هنر', image: imageData[1], down_vote: 5, up_vote: 45 },
    { id: 3, slug: '', title: 'آرشیتکت', image: imageData[2], down_vote: 145, up_vote: 45 },
    { id: 4, slug: '', title: 'آژانس رها و هنر', image: imageData[3], down_vote: 17, up_vote: 170 },
    { id: 5, slug: '', title: 'علاالدین مهاجر', image: imageData[4], down_vote: 94, up_vote: 39 },
    { id: 6, slug: '', title: 'آرشیتکت', image: imageData[0], down_vote: 175, up_vote: 58 },
    { id: 7, slug: '', title: 'آژانس رها و هنر', image: imageData[1], down_vote: 12, up_vote: 478 },
    { id: 8, slug: '', title: 'علاالدین مهاجر', image: imageData[4], down_vote: 18, up_vote: 45 },
    { id: 9, slug: '', title: 'آرشیتکت', image: imageData[3], down_vote: 54, up_vote: 36 },
    { id: 10, slug: '', title: 'آژانس رها و هنر', image: imageData[2], down_vote: 28, up_vote: 78 },
    { id: 11, slug: '', title: 'علاالدین مهاجر', image: imageData[1], down_vote: 33, up_vote: 99, ads: true },
];

const brandData: BrandType[] = [
    {
        title: 'شرکت کاشانه',
        slug: 'شرکت_کاشانه',
        description:
            '<p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است. برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد. </p>',
        gallery: [imageData[1], imageData[2], imageData[3]],
        main_image: imageData[1],
        average_stars: 4.4,
        total_orders: 22,
        telegram: 'https://t.me/example',
        instagram: 'https://www.instagram.com/example',
        twitter: 'https://twitter.com/example',
        website: 'https://example.ir',
        address: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        phone: '+989012345678',
    },
    {
        title: 'شرکت علاالدین',
        slug: 'شرکت_علاالدین',
        description:
            '<p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است. برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد. </p>',
        gallery: [imageData[2], imageData[3], imageData[4]],
        main_image: imageData[2],
        average_stars: 5,
        total_orders: 22,
    },
    {
        title: 'شرکت عظیمی',
        slug: 'شرکت_عظیمی',
        description:
            '<p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است. برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد. </p>',
        gallery: [imageData[5], imageData[6], imageData[2]],
        main_image: imageData[5],
        average_stars: 3,
        total_orders: 22,
    },
    {
        title: 'شرکت آریانا',
        slug: 'شرکت_آریانا',
        description:
            '<p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است. برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد. </p>',
        gallery: [imageData[6], imageData[3], imageData[4]],
        main_image: imageData[6],
        average_stars: 2.8,
        total_orders: 22,
    },
    {
        title: 'شرکت کاشانه طلایی',
        slug: 'شرکت_کاشانه_طلایی',
        description:
            '<p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است. برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد. </p>',
        gallery: [imageData[8], imageData[7], imageData[6]],
        main_image: imageData[8],
        average_stars: 5,
        total_orders: 22,
    },
    {
        title: 'کیلینیک درمان',
        slug: 'کیلینیکـدرمان',
        description:
            '<p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است. برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد. </p>',
        gallery: [imageData[9], imageData[3], imageData[4]],
        main_image: imageData[9],
        average_stars: 4.6,
        total_orders: 22,
    },
];

const serviceData: ServiceType[] = [
    {
        title: 'اقامت کوتاه',
        id: 1,
        created_at: '',
        slug: 'اقامت_کوتاه',
        description:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        use_terms:
            '<p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        gallery: [imageData[10], imageData[11], imageData[9]],
        cash_back: 5,
        cash_back_type: CacheBackTypeEnum.percent,
        avg_star: 3,
        total_orders: 22,
        categories: [categoryData.fa[0]],
        brand: brandData[0],
        is_ordered: 0,
    },
    {
        title: 'بلیط پرواز',
        id: 1,
        created_at: '',
        slug: 'بلیط_پرواز',
        description:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        use_terms:
            '<p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        gallery: [imageData[6], imageData[7], imageData[10]],
        cash_back: 20,
        cash_back_type: CacheBackTypeEnum.constant,
        avg_star: 4.8,
        total_orders: 22,
        categories: [categoryData.fa[0]],
        brand: brandData[1],
        is_ordered: 0,
    },
    {
        title: 'ثبت شرکت',
        id: 1,
        created_at: '',
        slug: 'ثبت_شرکت',
        description:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        use_terms:
            '<p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        gallery: [imageData[11], imageData[8], imageData[4]],
        cash_back: 5,
        cash_back_type: CacheBackTypeEnum.percent,
        avg_star: 5,
        total_orders: 22,
        categories: [categoryData.fa[0]],
        brand: brandData[2],
        is_ordered: 0,
    },
    {
        title: 'صرافی',
        id: 1,
        created_at: '',
        slug: 'صرافی',
        description:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        use_terms:
            '<p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        gallery: [imageData[7], imageData[10], imageData[5]],
        cash_back: 10,
        cash_back_type: CacheBackTypeEnum.percent,
        avg_star: 3.4,
        total_orders: 22,
        categories: [categoryData.fa[0]],
        brand: brandData[3],
        is_ordered: 0,
    },
    {
        title: 'بیمه بدنه خودرو',
        id: 1,
        created_at: '',
        slug: 'بیمه_بدنه_خودرو',
        description:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        use_terms:
            '<p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        gallery: [imageData[9], imageData[4], imageData[3]],
        cash_back: 5,
        cash_back_type: CacheBackTypeEnum.percent,
        avg_star: 4.4,
        total_orders: 22,
        categories: [categoryData.fa[0]],
        brand: brandData[4],
        is_ordered: 0,
    },
    {
        title: 'بیمه درمانی',
        id: 1,
        created_at: '',
        slug: 'بیمهـدرمانی',
        description:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        use_terms:
            '<p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        gallery: [imageData[10], imageData[6], imageData[11]],
        extra_service: ['خدمت حمل و نقل رایگان', 'تغذیه رایگان'],
        avg_star: 4.1,
        total_orders: 22,
        categories: [categoryData.fa[0]],
        brand: brandData[5],
        is_ordered: 0,
    },
];

const bilingualServiceData = {
    fa: [
        {
            image: 'https://iili.io/JyZMfm7.md.jpg',
            title: 'اقامت کوتاه مدت',
            brand: 'شرکت کاشانه',
            rate: 4.1,
            count: 122,
            id: 1,
            created_at: '',
            is_ordered: 0,
        },
        {
            image: 'https://iili.io/JyZMeBs.md.jpg',
            title: 'بلیط پرواز',
            brand: 'شرکت علاالدین',
            rate: 3.9,
            count: 85,
            id: 1,
            created_at: '',
            is_ordered: 0,
        },
        {
            image: 'https://iili.io/JyZV3Ga.md.jpg',
            title: 'ثبت شرکت',
            brand: 'شرکت عظیمی',
            rate: 3.7,
            count: 34,
            id: 1,
            created_at: '',
            is_ordered: 0,
        },
        {
            image: 'https://iili.io/JyZVluS.md.jpg',
            title: 'صرافی',
            brand: 'شرکت آریانا',
            rate: 4.4,
            count: 44,
            id: 1,
            created_at: '',
            is_ordered: 0,
        },
    ],
    en: [
        {
            image: 'https://iili.io/JyZMfm7.md.jpg',
            title: 'Temporary Resident',
            brand: 'Kashaneh Co',
            rate: 4.1,
            count: 122,
            id: 1,
            created_at: '',
            is_ordered: 0,
        },
        {
            image: 'https://iili.io/JyZMeBs.md.jpg',
            title: 'Flight ticket',
            brand: 'Alaedin Travel',
            rate: 3.9,
            count: 85,
            id: 1,
            created_at: '',
            is_ordered: 0,
        },
        {
            image: 'https://iili.io/JyZV3Ga.md.jpg',
            title: 'Company Registration',
            brand: 'Azimi Co',
            rate: 3.7,
            count: 34,
            id: 1,
            created_at: '',
            is_ordered: 0,
        },
        {
            image: 'https://iili.io/JyZVluS.md.jpg',
            title: 'Exchange',
            brand: 'Ariana Co',
            rate: 4.4,
            count: 44,
            id: 1,
            created_at: '',
            is_ordered: 0,
        },
    ],
};

const userData: ProfileType[] = [
    {
        email: 'example@mail.com',
        first_name: 'علیرضا احمدی',
        profile: imageData[0],
        id: 1,
    },
    {
        email: 'example@mail.com',
        first_name: 'مهرداد کاظمی',
        profile: imageData[0],
        id: 2,
    },
    {
        email: 'example@mail.com',
        first_name: 'علیرضا محمدی نیا',
        profile: imageData[0],
        id: 3,
    },
    {
        email: 'example@mail.com',
        first_name: 'مسعود روشن پژوه',
        profile: imageData[0],
        id: 4,
    },
];

const reviewData: ReviewType[] = [
    {
        user: userData[0],
        service: serviceData[0],
        comment:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون.',
        rate: 4,
        upload_date: '2024-04-25T07:35:39.367Z',
        created_at: '',
        user_fullname: '',
        user_profile: imageData[0],
    },
    {
        user: userData[1],
        service: serviceData[1],
        comment:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است یک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنا.',
        rate: 3,
        upload_date: '2024-04-25T07:35:39.367Z',
        created_at: '',
        user_fullname: '',
        user_profile: imageData[0],
    },
    {
        user: userData[2],
        service: serviceData[2],
        comment:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.',
        rate: 5,
        upload_date: '2024-04-25T07:35:39.367Z',
        created_at: '',
        user_fullname: '',
        user_profile: imageData[0],
    },
    {
        user: userData[3],
        service: serviceData[3],
        comment:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.',
        rate: 4,
        upload_date: '2024-04-25T07:35:39.367Z',
        created_at: '',
        user_fullname: '',
        user_profile: imageData[0],
    },
    {
        user: userData[1],
        service: serviceData[2],
        comment:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.',
        rate: 3,
        upload_date: '2024-04-25T07:35:39.367Z',
        created_at: '',
        user_fullname: '',
        user_profile: imageData[0],
    },
];

const orderData: OrderType[] = [
    {
        id: 1,
        user: userData[0],
        service: serviceData[0],
        created_at: '2024-04-25T07:35:39.367Z',
        verification_status: OrderStatusEnum.pending,
        comment: 'طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.',
        upload_date: '2024-07-25T07:35:39.367Z',
        confirm_date: '2024-03-25T07:35:39.367Z',
        rejected_date: '2024-09-25T07:35:39.367Z',
        tacking_code: '54848sds541',
        featured: false,
        user_fullname: '',
        user_profile: imageData[0],
    },
    {
        id: 2,
        user: userData[1],
        service: serviceData[1],
        created_at: '2024-04-25T07:35:39.367Z',
        verification_status: OrderStatusEnum.pending,
        rate: 2,
        reviews: [RateCommentEnum.goodInformation, RateCommentEnum.goodPrice],
        comment: 'طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.',
        purchase_documents: imageData,
        upload_date: '2024-07-25T07:35:39.367Z',
        confirm_date: '2024-03-25T07:35:39.367Z',
        rejected_date: '2024-09-25T07:35:39.367Z',
        tacking_code: '54848sds541',
        featured: false,
        user_fullname: '',
        user_profile: imageData[0],
    },
    {
        id: 3,
        user: userData[2],
        service: serviceData[2],
        created_at: '2024-04-25T07:35:39.367Z',
        verification_status: OrderStatusEnum.approved_by_admin,
        rate: 3,
        reviews: [RateCommentEnum.goodQuality, RateCommentEnum.goodSpace, RateCommentEnum.badSupport],
        comment: 'طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.',
        upload_date: '2024-07-25T07:35:39.367Z',
        confirm_date: '2024-03-25T07:35:39.367Z',
        rejected_date: '2024-09-25T07:35:39.367Z',
        tacking_code: '54848sds541',
        featured: false,
        user_fullname: '',
        user_profile: imageData[0],
    },
    {
        id: 4,
        user: userData[3],
        service: serviceData[3],
        created_at: '2024-04-25T07:35:39.367Z',
        verification_status: OrderStatusEnum.approved_by_brand,
        upload_date: '2024-07-25T07:35:39.367Z',
        confirm_date: '2024-03-25T07:35:39.367Z',
        rejected_date: '2024-09-25T07:35:39.367Z',
        tacking_code: '54848sds541',
        featured: false,
        user_fullname: '',
        user_profile: imageData[0],
    },
    {
        id: 5,
        user: userData[4],
        service: serviceData[4],
        created_at: '2024-04-25T07:35:39.367Z',
        verification_status: OrderStatusEnum.rejected_by_admin,
        rate: 5,
        reviews: [RateCommentEnum.goodSpace, RateCommentEnum.goodQuality, RateCommentEnum.badResponse],
        comment: 'طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.',
        purchase_documents: imageData,
        upload_date: '2024-07-25T07:35:39.367Z',
        confirm_date: '2024-03-25T07:35:39.367Z',
        rejected_date: '2024-09-25T07:35:39.367Z',
        tacking_code: '54848sds541',
        featured: false,
        user_fullname: '',
        user_profile: imageData[0],
    },
];

const eventCategory: EventCategoryType[] = [
    {
        title: 'بیزینس',
        slug: 'بیزینس',
    },
    {
        title: 'تحصیلی',
        slug: 'تحصیلی',
        parent: {
            title: 'اجتماعی',
            slug: 'اجتماعی',
        },
    },
    {
        title: 'خانوادگی',
        slug: 'خانوادگی',
        parent: {
            title: 'اجتماعی',
            slug: 'اجتماعی',
        },
    },
    {
        title: 'تفریحی',
        slug: 'تفریحی',
        parent: {
            title: 'اجتماعی',
            slug: 'اجتماعی',
        },
    },
    {
        title: 'اجتماعی',
        slug: 'اجتماعی',
    },
    {
        title: 'ورزشی',
        slug: 'ورزشی',
    },
    {
        title: 'زبان',
        slug: 'زبان',
    },
];

const events: EventType[] = [
    {
        id: 1,
        title: 'دورهمی خانوادگی',
        slug: 'دورهمی_خانوادگی',
        address: '21 Cumberland St S, Thunder Bay, Ontario',
        place: 'Downtown Toronto',
        event_date: '2024-01-25T07:35:39.367Z',
        capacity: 20,
        participant: 17,
        description:
            '<p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        conditions:
            '<li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li> <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li> <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li> <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li> <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li>',
        price: 12,
        latitude: 13.4134995,
        longitude: 12.226293,
        host: userData[0],
        gallery: [imageData[0], imageData[1], imageData[2]],
        main_image: imageData[2],
        category: eventCategory[2],
    },
    {
        id: 2,
        title: 'دورهمی تفریحی',
        slug: 'دورهمی_تفریحی',
        address: '21 Cumberland St S, Thunder Bay, Ontario',
        place: 'Downtown Toronto',
        event_date: '2024-01-25T07:35:39.367Z',
        capacity: 15,
        participant: 7,
        description:
            '<p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        conditions:
            '<li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li> <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li> <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li> <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li> <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li>',
        price: 78,
        latitude: 13.4134995,
        longitude: 12.226293,
        host: userData[1],
        gallery: [imageData[0], imageData[1], imageData[2]],
        main_image: imageData[1],
        category: eventCategory[3],
    },
    {
        id: 3,
        title: 'دورهمی ورزشی',
        slug: 'دورهمی_ورزشی',
        address: '21 Cumberland St S, Thunder Bay, Ontario',
        place: 'Downtown Toronto',
        event_date: '2024-01-25T07:35:39.367Z',
        capacity: 10,
        participant: 1,
        description:
            '<p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        conditions:
            '<li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li> <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li> <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li> <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li> <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li>',
        price: 78,
        latitude: 13.4134995,
        longitude: 12.226293,
        host: userData[2],
        gallery: [imageData[0], imageData[1], imageData[2]],
        main_image: imageData[0],
        category: eventCategory[5],
    },
    {
        id: 4,
        title: 'دورهمی اجتماعی',
        slug: 'دورهمی_اجتماعی',
        address: '21 Cumberland St S, Thunder Bay, Ontario',
        place: 'Downtown Toronto',
        event_date: '2024-01-25T07:35:39.367Z',
        capacity: 12,
        participant: 4,
        description:
            '<p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        conditions:
            '<li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li> <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li> <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li> <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li> <li>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</li>',
        price: 0,
        latitude: 13.4134995,
        longitude: 12.226293,
        host: userData[1],
        gallery: [imageData[0], imageData[1], imageData[2]],
        main_image: imageData[3],
        category: eventCategory[4],
    },
];

const userCharacter: DatingProfileType[] = [
    {
        id: 1,
        name: 'باران',
        birth_year: '1998',
        gender: GenderEnum.female,
        telegram_id: 'barans1998',
        location: { name: 'torento', id: 1, type: LocationEnum.city },
        [PersonalInfoEnum.height]: HeightEnum.from150to160,
        [PersonalInfoEnum.sleep]: SleepEnum.earlyBird,
        gallery: [imageData[0], imageData[2], imageData[1], imageData[3]],
        EI: 42,
        NS: 75,
        FT: 26,
        JP: 33,
        is_active: true,
    },
];

/* const houses: HouseType[] = [
    {
        id: 1,
        title: 'اتاق معمولی با سرویس و حمام مشترک',
        slug: 'room',
        gallery: [imageData[0], imageData[1], imageData[2]],
        main_image: imageData[5],
        place: 'Ontario - Richmond Hill',
        buildingType: 'Detached House',
        leaseType: LeaseEnum.share,
        price: 1500,
        tip: 'ساکن آقا به دنبال همخانه آقا',
        facilities: ['پارکینگ', 'انباری', 'باشگاه'],
        limitations: ['حیوانات خانگی', 'سیگار کشیدن'],
        conditions:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،',
        startDate: '2024-07-25T07:35:39.367Z',
        endDate: '2024-03-25T07:35:39.367Z',
        name: 'علی',
        lastName: 'منصوری',
        age: 31,
        hostDesc:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است',
        hostGender: GenderEnum.female,
        roommateGender: GenderEnum.female,
        hostProfile: imageData[1],
    },
    {
        id: 2,
        title: 'اتاق معمولی با سرویس و حمام مشترک',
        slug: 'room',
        gallery: [imageData[0], imageData[1], imageData[2]],
        main_image: imageData[4],
        place: 'Ontario - Richmond Hill',
        buildingType: 'Detached House',
        leaseType: LeaseEnum.independent,
        price: 1700,
        facilities: ['پارکینگ', 'انباری', 'باشگاه'],
        limitations: ['حیوانات خانگی', 'سیگار کشیدن'],
        conditions:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،',
        startDate: '2024-07-25T07:35:39.367Z',
        endDate: '2024-03-25T07:35:39.367Z',
        name: 'علی',
        lastName: 'منصوری',
        age: 31,
        hostDesc:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است',
        hostGender: GenderEnum.female,
        roommateGender: GenderEnum.female,
        hostProfile: imageData[2],
    },
    {
        id: 3,
        title: 'اتاق معمولی با سرویس و حمام مشترک',
        slug: 'room',
        gallery: [imageData[0], imageData[1], imageData[2]],
        main_image: imageData[3],
        place: 'Ontario - Richmond Hill',
        buildingType: 'Detached House',
        leaseType: LeaseEnum.share,
        price: 3500,
        tip: 'ساکن خانم به دنبال همخانه آقا',
        facilities: ['پارکینگ', 'انباری', 'باشگاه'],
        limitations: ['حیوانات خانگی', 'سیگار کشیدن'],
        conditions:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،',
        startDate: '2024-07-25T07:35:39.367Z',
        endDate: '2024-03-25T07:35:39.367Z',
        name: 'علی',
        lastName: 'منصوری',
        age: 31,
        hostDesc:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است',
        hostGender: GenderEnum.female,
        roommateGender: GenderEnum.female,
        hostProfile: imageData[0],
    },
]; 

const roommates: RoommatesType[] = [
    {
        id: 1,
        name: 'آرش',
        age: 22,
        place: 'Ontario - Richmond Hill',
        title: 'اتاق معمولی با سرویس و حمام مشترک',
        slug: 'room',
        buildingType: 'Detached House',
        leaseType: RoommatesLeaseEnum.shareFemale,
        price: 1422,
        facilities: ['پارکینگ', 'انباری', 'باشگاه'],
        limitations: ['حیوانات خانگی', 'سیگار کشیدن'],
        conditions:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،',
        startDate: '2024-07-25T07:35:39.367Z',
        endDate: '2024-03-25T07:35:39.367Z',
        lastName: 'منصوری',
        hostDesc:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است',
        hostGender: GenderEnum.female,
        roommateGender: GenderEnum.female,
        hostProfile: imageData[1],
    },
    {
        id: 3,
        name: 'محمد',
        age: 34,
        place: 'Ontario - Richmond Hill',
        title: 'اتاق معمولی با سرویس و حمام مشترک',
        slug: 'room',
        buildingType: 'Detached House',
        leaseType: RoommatesLeaseEnum.shareMale,
        price: 1422,
        facilities: ['پارکینگ', 'انباری', 'باشگاه'],
        limitations: ['حیوانات خانگی', 'سیگار کشیدن'],
        conditions:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،',
        startDate: '2024-07-25T07:35:39.367Z',
        endDate: '2024-03-25T07:35:39.367Z',
        lastName: 'منصوری',
        hostDesc:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است',
        hostGender: GenderEnum.female,
        roommateGender: GenderEnum.female,
        hostProfile: imageData[1],
    },
    {
        id: 4,
        name: 'علیرضا',
        age: 28,
        place: 'Ontario - Richmond Hill',
        title: 'اتاق معمولی با سرویس و حمام مشترک',
        slug: 'room',
        buildingType: 'Detached House',
        leaseType: RoommatesLeaseEnum.independent,
        price: 1422,
        facilities: ['پارکینگ', 'انباری', 'باشگاه'],
        limitations: ['حیوانات خانگی', 'سیگار کشیدن'],
        conditions:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،',
        startDate: '2024-07-25T07:35:39.367Z',
        endDate: '2024-03-25T07:35:39.367Z',
        lastName: 'منصوری',
        hostDesc:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است',
        hostGender: GenderEnum.female,
        roommateGender: GenderEnum.female,
        hostProfile: imageData[1],
    },
    {
        id: 5,
        name: 'باران',
        age: 19,
        place: 'Ontario - Richmond Hill',
        title: 'اتاق معمولی با سرویس و حمام مشترک',
        slug: 'room',
        buildingType: 'Detached House',
        leaseType: RoommatesLeaseEnum.shareFemale,
        price: 1422,
        facilities: ['پارکینگ', 'انباری', 'باشگاه'],
        limitations: ['حیوانات خانگی', 'سیگار کشیدن'],
        conditions:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،',
        startDate: '2024-07-25T07:35:39.367Z',
        endDate: '2024-03-25T07:35:39.367Z',
        lastName: 'منصوری',
        hostDesc:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است',
        hostGender: GenderEnum.female,
        roommateGender: GenderEnum.female,
        hostProfile: imageData[1],
    },
];

const profileHouses: {
    emptyData: never[];
    dummyData: HouseType[];
    detailData?: RoommatesType;
} = {
    emptyData: [],
    dummyData: [houses[0], houses[1], houses[2]],
    detailData: roommates[0],
}; */

const specialServices = {
    fa: [
        {
            title: 'کمک به رشد و توسعه کسب و کارها',
            description: `توسعه محصول، طراحی وب سایت و اپلیکیشن. تولید محتوای شبکه های اجتماعی. طراحی و دیزاین مورد نیاز با بیزینس. بیهنه سازی سایت برای موتورهای جستجو و تبلیغات آنلاین (SEO و SEA)`,
            img: 'https://iili.io/Jy4XgMQ.md.jpg',
        },
        {
            title: 'خدمات در ایران برای اقوام و آشنایان مقیم کانادا',
            description: `هماهنگی برگزاری جشن و مراسم. هماهنگی خرید و امور خدماتی. هماهنگی امور پزشکی و سفر. هماهنگی امور اداری و حقوقی`,
            img: 'https://iili.io/Jy4LH0P.md.jpg',
        },
        {
            title: 'خدمات ویژه به تازه واردین',
            description: `ترانسفر و اقامت کوتاه مدت. انتقال پول با کمترین کارمزد. بازحساب بانکی و ترجمه مدارک. اخذ گواهینامه رانندگی`,
            img: 'https://iili.io/Jy6YpZ7.md.jpg',
        },
        {
            title: 'ویزا و مهاجرت',
            description: 'ورک پرمیت ایرانیان. روش‌های اقامت دائم استانی. روش‌های اکسپرس انتری. روش‌های پناهندگی',
            img: 'https://iili.io/Jy6Egj4.md.jpg',
        },
        // {
        //     title: '',
        //     description: '',
        //     img: '',
        // },
    ],
    en: [
        {
            title: 'Business Growth and Development Support',
            description: `Product Development, Website and App Design. Social Media Content Production. Business Design and Branding. Search Engine Optimization (SEO) and Online Advertising (SEA)`,
            img: 'https://iili.io/Jy4XgMQ.md.jpg',
        },
        {
            title: 'Services in Iran for Relatives and Friends of Residents in Canada',
            description: `Coordination for Celebrations and Events. Coordination for Purchases and Service Needs. Coordination for Medical and Travel Services. Coordination for Administrative and Legal Affairs`,
            img: 'https://iili.io/Jy4LH0P.md.jpg',
        },
        {
            title: 'Special Services for Newcomers',
            description:
                'Transfer and Short-Term Accommodation. Low-Fee Money Transfers. Bank Account Opening and Document Translation. Driver’s License Acquisition',
            img: 'https://iili.io/Jy6YpZ7.md.jpg',
        },
        {
            title: 'Visa and Immigration',
            description: 'Work Permits. Provincial Nominee Programs (PR). Express Entry Programs. Refugee Programs',
            img: 'https://iili.io/Jy6Egj4.md.jpg',
        },
    ],
};

function parseService(e: {
    image: string;
    title: string;
    brand: string;
    rate: number;
    count: number;
    id: number;
    created_at: string;
    is_ordered: number;
}) {
    const result: ServiceType = {
        title: 'بلیط پرواز',
        slug: 'بلیط_پرواز',
        description:
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        use_terms:
            '<p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.</p>',
        gallery: [
            {
                id: 1,
                file: e.image,
                alt: 'Main image',
                type: AttachmentTypeEnum.image,
                usage: AttachmentUsageEnum.content,
            },
        ],
        cash_back: 20,
        cash_back_type: CacheBackTypeEnum.constant,
        avg_star: 4.8,
        total_orders: 22,
        categories: [categoryData.fa[0]],
        brand: brandData[1],
        id: 1,
        created_at: '',
        is_ordered: 0,
    };
    const file = result.gallery?.length ? result.gallery[0] : undefined;
    if (file) {
        file.file = e.image;
        result.gallery = [file];
    }
    result.title = e.title;
    result.brand = { ...result.brand, title: e.brand };
    result.avg_star = e.rate;
    result.total_orders = e.count;
    result.id = e.id;
    result.created_at = e.created_at;
    result.is_ordered = e.is_ordered;
    return result;
}

const participateRequest: ParticipateType[] = [
    {
        id: 0,
        user: userData[0],
        count: '3',
        cerated_at: '2024-01-25T07:35:39.367Z',
        description:
            'پلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد.',
        status: ParticipateStatusEnum.pending,
        event: events[0],
    },
    {
        id: 1,
        user: userData[0],
        count: '8',
        cerated_at: '2024-01-25T07:35:39.367Z',
        description:
            'پلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد.',
        status: ParticipateStatusEnum.success,
        event: events[1],
    },
    {
        id: 2,
        user: userData[0],
        count: '4',
        cerated_at: '2024-01-25T07:35:39.367Z',
        description:
            'پلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد.',
        status: ParticipateStatusEnum.pending,
        event: events[2],
    },
    {
        id: 3,
        user: userData[0],
        count: '3',
        cerated_at: '2024-01-25T07:35:39.367Z',
        description:
            'پلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد.',
        status: ParticipateStatusEnum.rejected,
        event: events[3],
    },
];
export const __DummyData = {
    userData,
    imageData,
    categoryData,
    serviceData,
    brandData,
    reviewData,
    orderData,
    events,
    eventCategory,
    specialServices,
    bilingualServiceData,
    parseService,
    participateRequest,
    userCharacter,
    BestBrands,
    // houses,
    // roommates,
    // profileHouses,
};
